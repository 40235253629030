<template>
  <div class="setting-content">
    <a-form :model="field" layout="vertical" style="padding: 10px">
      <a-divider orientation="left">基本配置:{{defaultConfig[field.type].label}}</a-divider>

      <a-form-item label="名称">
        <a-input v-model:value="field.label" />
      </a-form-item>
      <a-form-item label="描述文字" >
        <a-input v-model:value="field.extra" />
      </a-form-item>
      <a-form-item label="是否必填" >
        <a-radio-group v-model:value="field.is_required">
          <a-radio :value="false">非必填</a-radio>
          <a-radio :value="true">必填</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="是否显示清空按钮" v-if="field.allowClear !== undefined">
        <a-switch v-model:checked="field.allowClear" />
      </a-form-item>
      <a-form-item label="模式" v-if="field.type === 'select'">
        <a-radio-group v-model:value="field.mode">
          <a-radio-button value="default">单选</a-radio-button>
          <a-radio-button value="multiple">多选</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <template v-if="'select,treeselect,cascader,checkbox,radio'.indexOf(field.type) > -1">
        <a-form-item label="预设数据">
          <a-button :size="'small'" @click="addOptions()">
            <template #icon><plus-outlined /></template>
          </a-button>

          <div v-for="(item, index) in options" v-bind:key="index" style="display: flex">
            <a-input v-model:value="item.label" @change="changeOption(index)" />
            <a-button :size="'small'" @click="removeOptions(index)">
              <template #icon><minus-outlined /></template>
            </a-button>
          </div>
        </a-form-item>
        <a-form-item label="报警数据" extra="点检选中这些值，会提示点检到异常数据引起工人注意。">
          <a-checkbox-group v-model:value="field.warning_selected"  :options="options" />
        </a-form-item>

      </template>
      <template v-if="'number,weight,volume'.indexOf(field.type) > -1">
        <a-form-item label="报警数据" extra="点检选中这些值，会提示点检到异常数据引起工人注意。">
          <a-button :size="'small'" @click="addWarning()">
            <template #icon><plus-outlined /></template>
            添加新规则
          </a-button>
          <a-list item-layout="horizontal" :data-source="field.warning_rules">
            <template #renderItem="{ item, index }">
              <a-list-item>
                <a-list-item-meta
                >
                  <template #title>
                    {{ item.title }}
                  </template>
                  <template #avatar>
                    <a-button :size="'small'" @click="removeWarning(index)">
                      <template #icon><minus-outlined /></template>
                    </a-button>
                  </template>
                </a-list-item-meta>
              </a-list-item>
            </template>
          </a-list>
        </a-form-item>

      </template>
      <a-divider orientation="left">高级配置</a-divider>
      <a-form-item v-if="field.type !== 'divider'" label="字段标记">
        <a-input v-model:value="field.name" />
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { defaultRules, defaultValue, defaultPlaceholder, defaultConfig } from '../defaultConfig';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons-vue';

export default {
  name: 'FieldSetting1',
  components: {
    PlusOutlined,
    MinusOutlined,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: false,
    },
  },
  methods: {
    addOptions() {
      this.$emit('add_options');
    },
    removeOptions(index) {
      this.$emit('remove_options', index);
    },

    addWarning() {
      this.$emit('add_warning');
    },
    removeWarning(index) {
      this.$emit('remove_warning', index);
    },
    changeOption(index) {
      this.$emit('change_option', index);
    },
  },
  data() {
    return {
      defaultPlaceholder: defaultPlaceholder,
      defaultValue: defaultValue,
      defaultRules: defaultRules,
      defaultConfig: defaultConfig,
    };
  },
};
</script>
